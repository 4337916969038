<template>
  <v-dialog v-model="invdialog" scrollable max-width="800px">
    <template v-slot:activator="{ on }">
      <v-btn
        fab
        small
        color="primary"
        dark
        v-on="on"
        @click="searchAccessInvData(college_code)"
        ><v-icon style="font-size: 22px">group</v-icon></v-btn
      >
    </template>
    <v-card>
      <v-card-title
        ><span style="font-size: 16px"
          >Invigilators of {{ center_code }} - {{ center_name }}&nbsp;&nbsp;[{{
            college_code
          }}]</span
        >&nbsp;&nbsp;&nbsp;
        <v-divider class="my-1" vertical></v-divider>
        <v-spacer />
        <v-btn fab small color="#B71C1C" dark @click="invdialog = false"
          ><v-icon style="font-size: 22px">close</v-icon></v-btn
        >
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text style="height: 600px">
        <v-data-table
          :headers="headers"
          :items="invAccessList"
          class="elevation-1"
          hide-actions
        >
          <template v-slot:items="props">
            <td>{{ props.index + 1 }}</td>
            <td class="text-xs-left">
              {{ props.item.name }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <v-btn v-if="props.item.convener == 1" small color="#E16425" dark
                ><v-icon style="font-size: 22px">support_agent</v-icon>&nbsp;&nbsp;Convener</v-btn
              >
            </td>
            <td class="text-xs-left">{{ props.item.email }}</td>
            <td class="text-xs-left">{{ props.item.mobile }}</td>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import commonGet from "../../mixin/common_getters_mixin";
import commonExamYear from "../../mixin/exam_year_watch_mixin";
import common_login_getters from "../../mixin/common_login_getters";

export default {
  props: ["college_code", "center_code", "center_name"],
  mixins: [commonGet, commonExamYear, common_login_getters],
  data() {
    return {
      invdialog: false,
      loading: false,
      college_code: "",
      headers: [
        {
          text: "SL",
          align: "left",
          sortable: false,
          value: "name",
        },
        { text: "Name", value: "name", align: "left" },
        { text: "Email", value: "email", align: "left" },
        { text: "Phone", value: "mobile", align: "left" },
      ],
    };
  },

  created() {},

  computed: {
    invAccessList() {
      return this.$store.getters.accessedInvToMonitor;
    },
  },

  methods: {
    searchAccessInvData(Colcode) {
      this.loading = true;
      let param = {};
      param.exam_initiate_id = this.getExamInitiateId;
      param.exam_code = this.getLoggedInExam.code;
      param.exam_year = this.getLoggedInYear.year;
      param.college_code = Colcode;
      this.$store
        .dispatch("fetchInvAccessForMonitoring", param)
        .then((data) => {
          console.log("Tanvir data of hhhaaahh", data);
          that.loading = false;
        });
    },
  },
};
</script>

<style></style>
