<template>
  <v-layout row justify-center>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      persistent
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title class="text-uppercase"
            >{{ header_title ? header_title : "Absent Student List",
            }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark flat @click="close">Close</v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-card color="white">
            <v-layout row>
              <v-card flat>
                <v-card-text class="title">Student List</v-card-text>
              </v-card>
              <v-divider class="my-2" vertical></v-divider>
              <v-spacer />
            </v-layout>
          </v-card>
          <v-data-table
            :headers="headers"
            :items="getSchema"
            class="elevation-1"
            :total-items="pagination.totalItems"
            :pagination.sync="pagination"
            :loading="loading"
            item-key="id"
            :rows-per-page-items="pagination.rowsPerPageItems"
            :expand="expand"
          >
            <template v-slot:items="props">
              <td class="text-xs-left">
                {{ props.index + 1 }}
              </td>
              <td class="text-xs-left">
                {{ props.item.reg_no ? props.item.reg_no : "" }}
              </td>
              <td class="text-xs-left">
                {{ props.item.exam_roll_no ? props.item.exam_roll_no : "" }}
              </td>
              <td class="text-xs-left">
                {{ props.item.student_name ? props.item.student_name : "" }}
              </td>
              <td class="text-xs-left">
                {{
                  props.item.college_display_name
                    ? props.item.college_display_name
                    : ""
                }}
              </td>
              <td class="text-xs-left">
                {{ props.item.paper_code ? props.item.paper_code : "" }}
              </td>
            </template>

            <template v-slot:no-data> </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-layout>
</template>
<script>
import Vue from "vue";
import VeeValidate from "vee-validate";
import common_login_getters from "@/mixin/common_login_getters";

Vue.use(VeeValidate);

export default {
  mixins: [common_login_getters],
  props: ["absentListDialog", "manualAbsentSearchParam"],

  data: () => ({
    header_title: "",
    dialog: false,
    pagination: {
      rowsPerPage: 100,
      rowsPerPageItems: [100]
    },
    expand: false,
    loading: true,
    searchParam: {},
    formData: {},
    search: "",

    headers: [
      { text: "SL NO", align: "left", value: "" },
      { text: "REG NO", align: "left", value: "reg_no" },
      { text: "EXAM ROLL", align: "left", value: "exam_roll" },
      { text: "STUDENT NAME", align: "left", value: "student_name" },
      { text: "COLLEGE NAME", align: "left", value: "college_name" },
      { text: "PAPER CODE", align: "left", value: "paper_code" }
    ]
  }),

  computed: {
    getSchema() {
      return this.$store.getters.getRoomWiseAbsentList;
    }
  },

  methods: {
    setLimit() {
      const { sortBy, page, rowsPerPage } = this.pagination;
      this.searchParam.page = page;
      this.searchParam.limit = rowsPerPage;
      this.searchParam.sort = sortBy;
      this.searchParam.search = this.search;
    },

    getDataFromApi() {
      this.setLimit();
      this.searchParam.exam_initiate_id = this.manualAbsentSearchParam.exam_initiate_id;
      this.searchParam.center_code = this.manualAbsentSearchParam.center_code;
      this.searchParam.attendance_date = this.manualAbsentSearchParam.attendance_date;
      this.searchParam.room_no = this.manualAbsentSearchParam.room_no;
      this.$store
        .dispatch("roomWiseAbsentList", this.searchParam)
        .then(data => {
          this.header_title =
            "Absent Student List. Room no " +
            this.searchParam.room_no +
            " and Total Students " +
            data.total;
          this.loading = false;
          this.pagination.totalItems = data.total;
        });
    },

    close() {
      this.$emit("update:absentListDialog", false);
      setTimeout(() => {
        this.$emit("update:indexEdited", -1);
      }, 300);
    }
  },

  watch: {
    absentListDialog(val) {
      this.dialog = val;
      if (val) {
        this.getDataFromApi();
      }
    },
    dialog(val) {
      if (!val) this.close();
    }
  }
};
</script>

<style scoped>
.attach {
  border-collapse: collapse;
  width: 50%;
  margin: 0 auto;
}

.attach th,
.attach td {
  padding: 5px;
  text-align: center;
  border-bottom: 1px solid #ddd;
}

caption {
  display: table-caption;
  text-align: center;
  font-weight: bold;
}

table.detail-info {
  border-collapse: collapse;
  width: 100%;
}

.detail-info th,
.detail-info td {
  padding: 8px;
  text-align: left;
  border: 1px solid #ddd;
}
</style>
