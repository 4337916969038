<template>
  <v-dialog v-model="roomdialoug" scrollable max-width="800px">
    <template v-slot:activator="{ on }">
      <v-btn
        fab
        small
        color="#417826"
        dark
        v-on="on"
        @click="SearchRoomWiseSummary(center_code, ex_date)"
        ><v-icon style="font-size: 22px">list</v-icon></v-btn
      >
    </template>
    <v-card>
      <v-card-title
        ><span style="font-size: 16px"
          >Room wise Attendance {{ center_code }} -
          {{ center_name }}&nbsp;&nbsp;[{{ college_code }}]</span
        >&nbsp;&nbsp;&nbsp;
        <v-divider class="my-1" vertical></v-divider>
        <v-spacer />
        <v-btn fab small color="#B71C1C" dark @click="roomdialoug = false"
          ><v-icon style="font-size: 22px">close</v-icon></v-btn
        >
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <div>
          <studentDetails
            :absentListDialog.sync="absentListDialog"
            :manualAbsentSearchParam="manualAbsentSearchParam"
          ></studentDetails>
        </div>
        <v-data-table
          :headers="headers"
          :items="RoomWiseAttandanceData"
          class="elevation-1"
          hide-actions
        >
          <template v-slot:items="props">
            <td>{{ props.index + 1 }}</td>
            <td class="text-xs-left">{{ props.item.room_no }}</td>
            <td class="text-xs-left">{{ props.item.present_count }}</td>
            <td class="justify-center">
                {{ props.item.absent_count }}
            </td>
            <td class="justify-center">
              <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      fab
                      dark
                      small
                      color="warning"
                      v-on="on"
                      @click="showAbsentStudents(props.item)"
                    >
                      <v-icon>list</v-icon>
                    </v-btn>
                  </template>
                  <span>Absent Student List</span>
                </v-tooltip>
            </td>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import commonGet from "../../mixin/common_getters_mixin";
import commonExamYear from "../../mixin/exam_year_watch_mixin";
import common_login_getters from "../../mixin/common_login_getters";
import studentDetails from "./roomWiseAbsentList.vue";

export default {
  props: ["college_code", "center_code", "center_name", "ex_date"],
  mixins: [commonGet, commonExamYear, common_login_getters],
  components: {
    studentDetails
  },
  data() {
    return {
      roomdialoug: false,
      manualAbsentSearchParam: {},
      absentListDialog: false,
      loading: false,
      headers: [
        {
          text: "SL",
          align: "left",
          value: "name"
        },
        { text: "Room No.", value: "room_no", align: "left" },
        { text: "Present", value: "present_count", align: "left" },
        { text: "Absent", value: "absent_count", align: "left" },
        { text: "Absent Std List", value: "absent_count", align: "left" }
      ]
    };
  },

  computed: {
    RoomWiseAttandanceData() {
      return this.$store.getters.RoomWiseAttandanceCount;
    }
  },

  methods: {
    SearchRoomWiseSummary(Ccode, date) {
      this.loading = true;
      let param = {};
      param.exam_initiate_id = this.getExamInitiateId;
      param.center_code = Ccode;
      param.attendance_date = date;
      param.download = 0;
      this.$store.dispatch("fetchRoomwiseattendancecount", param).then(() => {
        this.loading = false;
      });
    },
    showAbsentStudents(item) {
      this.manualAbsentSearchParam = item;
      console.log("search param", this.manualAbsentSearchParam);
      this.absentListDialog = true;
    }
  }
};
</script>

<style></style>
